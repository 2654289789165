.policy-wrapper li {
    list-style: disc;
}

.policy-wrapper {
    padding-left: 8.385416vw;
    padding-right: 8.385416vw;
    padding-top: 5.385416vw;
    padding-bottom: 5.385416vw;

    color: #ffffffbf;
}

.policy-wrapper ul {
    margin-left: 20px;
}

h3.list_item {
    color: white;
    margin-top: 10px;
}


h4.list_item {
    color: white;
    margin-top: 10px;
}
.policy-wrapper h1 {
    color: white;
    width: 100%;
    font-size: 1.7rem;
    margin-bottom: 10px;
}

.policy-wrapper h1 i.fa.fa-arrow-left {
    color: white;
    margin-right: 10px;

}
.policy-wrapper h1 a {
    cursor: pointer;
}